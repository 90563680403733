<template>
  <div>
    <!-- 会议相关订单 2期wwl-->
    <div class="orderTypeList flex alignCenter">
      <span v-for="(im,ix) in tabList"
            :key="ix"
            :class="tabActive == ix ? 'typeCurr' : ''"
            @click="changeTab(ix)">{{im}}</span>
    </div>
    <div class="info_box">
      <template v-if='tabActive == 0'>
        <template v-if='AllList[tabActive].total > 0'>
          <div class="row_box"
               v-for="(item,index) in AllList[tabActive].data"
               @click="onDetail(item.id)"
               :key="item.id">
            <div class="top">
              <p>订单号：{{item.order_no}}</p>
              <p>订单状态：<span> {{ item.pay_status == 2 ? '待支付' : item.pay_status == 3 ? '已取消' : item.pay_status == 1 ? item.sign_status == 1 ? '已签到' : '待签到' : item.pay_status == 4 ? '退款中' : item.pay_status == 5 ? '已退款' : ''}}</span></p>
            </div>
            <div class="center">
              <div>
                <img :src="$global.regexImg(item.meet.logo_app)"
                     alt="">
                <p>{{ item.meet.meet_name }}</p>
              </div>
              <div>
                {{ item.meet.address }}
              </div>
              <div>
                <span>{{payList[item.pay_type-1]}}</span>
                <span v-if=' item.pay_type != 5'>{{ item.pay_status == 1 ? '已支付' : '未支付' }}</span>
              </div>
              <div>
                ¥{{item.amount}}
              </div>
              <div class="button_box">
                <p>
                  <el-button v-if='item.pay_status == 1'
                             :loading='loading && operationID ==  item.id && operationStatus == 3'
                             @click.stop="operationID = item.id;operationStatus = 4;onETicket(item.id,item)"
                             class="btn1">电子票</el-button>
                  <el-button class="btn1"
                             :loading='loading && operationID ==  item.id && operationStatus == 4'
                             v-if='item.pay_status == 1'
                             @click.stop="operationID = item.id;operationStatus = 4;onMealTicket(item.id,index,item)">餐票</el-button>
                </p>
                <el-button class="btn1"
                           v-if=" item.pay_status == 2"
                           @click.stop="onPay(item.id,item)">去支付</el-button>
                <el-button v-if=" item.pay_status == 2  /*|| (item.pay_status == 1 && item.pay_type == 5)*/ "
                           :loading='loading && operationID ==  item.id && operationStatus == 1'
                           @click.stop="operationID = item.id;operationStatus = 1;onCancel(item.id,index)">取消订单</el-button>
                <el-button v-if="item.pay_status == 1 && item.sign_status == 2 && (item.pay_type == 1 || item.pay_type == 2)"
                           :loading='loading && operationID ==  item.id && operationStatus == 2'
                           @click.stop="operationID = item.id;operationStatus = 2; onRefund(item.id,index)">退款</el-button>
                <p v-if="( item.pay_status != 1 && item.pay_status != 2)">--</p>
              </div>
            </div>
          </div>
        </template>
        <tempData v-else />
      </template>
      <template v-if='tabActive == 1'>
        <!-- {{AllList[1]}} {{AllList[tabActive].total > 0}} -->
        <div v-if='AllList[tabActive].total > 0'>
          <div class="row_box2"
               v-for="(item,index) in AllList[tabActive].data"
               @click="onDetail(item.id)"
               :key="item.id">
            <div class="top">
              <p>订单号：{{item.order_no}}</p>
              <p>订单状态：<span :class='item.status == 1 || item.status  == 2 ? "active":""'> {{item.status == 1 ? '待支付' : item.status == 2 ? '已支付' : item.status == 3 ? '已超时' : item.status == 4 ? '已取消' : item.status == 5 ?'退款成功' :''}}</span></p>
            </div>
            <div class="center">
              <div>
                <div class="img1">
                  <img :src="$global.regexImg(item.hotel.cover_pic)"
                       alt=""
                       v-if='item.hotel'>
                  <div class="img"
                       v-if='!item.hotel'></div>
                </div>

                <p class="p1"
                   v-if='item.hotel'>{{item.hotel.name}}</p>
                <p class="p12"
                   v-if='!item.hotel'></p>
                <p class="p2">{{item.date}}-{{item.end_date}}</p>
              </div>
              <div>
                ¥{{item.amount}}
              </div>
              <div class="button_box">
                <p>
                  <el-button class="btn1"
                             v-if="item.status == 1"
                             @click.stop="onPay(item.id)">去支付</el-button>
                  <el-button class="btn1"
                             v-if="item.status == 1 || item.status == 2"
                             @click.stop="onMerchant(item.hotel ? item.hotel.phone : '')">联系商家</el-button>
                </p>
                <el-button v-if="item.status == 1"
                           :loading='loading && operationID ==  item.id && operationStatus == -1'
                           @click.stop="operationID = item.id;operationStatus = -1;onCancel(item.id,index)">取消订单</el-button>
                <p v-if="!(item.status == 1 || item.status == 2)">--</p>
              </div>
            </div>
          </div>
        </div>
        <tempData v-else />
      </template>
    </div>
    <div class="pagination"
         v-if='AllList[tabActive].total > 0 '>
      <el-pagination @size-change="sizeChange"
                     :background='true'
                     @current-change="pageChange"
                     :current-page="AllList[tabActive].page"
                     :page-sizes="sizes"
                     :page-size="AllList[tabActive].size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="AllList[tabActive].total">
      </el-pagination>
    </div>
    <textarea style="opacity:0;width:1px;height:1px"
              id="input"
              key="textarea">复制</textarea>
    <!-- 参票 -->
    <!--   :close-on-click-modal='false' -->
    <el-dialog :visible.sync="mealTicket.visible"
               :show-close='false'
               center
               width="500px">
      <div class="mealTicket_box">
        <div class="tab_box">
          <span v-for="(im,ix) in mealTicket.tabList"
                :key="ix"
                :class="mealTicket.tabActive == ix ? 'active' : ''"
                @click="changeMealTicket(ix)">{{im}}</span>
        </div>
        <div class="content">
          <div v-if='mealTicket.AllList[mealTicket.tabActive].total >0'>
            <div class="row"
                 :class="mealTicket.tabActive  == 1 ? 'tab2' :''"
                 v-for='(item,index) in mealTicket.AllList[mealTicket.tabActive].data'
                 :key='index'>
              <div class="left">
                <p>{{item.meal_ticket.name}}</p>
                <p> {{item.type == 1 ?'早餐' :item.type== 2 ?'午餐' :'晚餐'}}</p>
                <p><span>{{item.meal_ticket.eat_type}}</span><span>用餐{{item.num}}人</span></p>
                <p><span>{{item.meal_ticket.start_time}} - {{item.meal_ticket.end_time}}</span></p>
                <p>地点：{{item.meal_ticket.address}}</p>
              </div>
              <div class="right">
                <img :src="$global.regexImg(item.qrcode)"
                     mode="aspectFit"
                     class="code"
                     v-if='mealTicket.tabActive == 0'>
                <img src="@/assets/img/meeting/order_08.png"
                     mode="aspectFit"
                     v-if='mealTicket.tabActive == 1 && item.type == 2'>
                <img src="@/assets/img/meeting/order_07.png"
                     mode="aspectFit"
                     v-if='mealTicket.tabActive == 1 && item.type == 3'>
              </div>
            </div>
          </div>
          <tempData v-else />
          <div class="pop_close">
            <i></i>
            <img src="@/assets/img/meeting/order_20.png"
                 @click='mealTicket.visible = false'
                 alt="">
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 电子票 -->
    <!--   :close-on-click-modal='false' -->
    <el-dialog :visible.sync="electronicTicket.visible"
               :show-close='false'
               center
               width="480px">
      <div class="electronicTicket_box">
        <div class="content_box">
          <img :src="$global.regexImg(electronicTicket.obj.qrcode)"
               alt="">
          <p v-if='userInfo &&userInfo.real_name'
             class="p1">{{userInfo.real_name}}</p>
          <p v-if='userInfo && userInfo.unit'
             class="p2">{{userInfo.unit}}</p>
        </div>
        <div class="pop_close">
          <i></i>
          <img src="@/assets/img/meeting/order_20.png"
               @click='electronicTicket.visible = false'
               alt="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_hotelOrder, get_schedule_list, set_cancelJoin, set_cancelRoom, get_mealTicket, set_refund, set_ticketPay } from '@/utils/Api/meeting'
import tempData from '@/components/tempData'
export default {
  components: { tempData },
  props: [],
  data () {
    return {
      payList: ['支付宝', '微信', '现场支付', '线下转账', '免费'],
      tabActive: 0,
      tabList: ['参会门票', '酒店订单'],
      sizes: [5, 10, 15, 20, 30, 50, 100],
      pageSize: 10,
      pageCurrent: 1,
      total: 0,
      operationID: null,
      operationStatus: null,
      loading: false,
      AllList: [
        { data: [], page: 1, total: 0, size: 5, first: false },
        { data: [], page: 1, total: 0, size: 5, first: false },
      ],
      mealTicket: {
        visible: false,
        id: null,
        meetId: null,
        AllList: [
          { data: [], page: 1, total: 0, size: 5, first: false },
          { data: [], page: 1, total: 0, size: 5, first: false },
        ],
        tabActive: 0,
        tabList: ['待使用', '已失效'],
      },
      electronicTicket: {
        visible: false,
        id: null,
        obj: {
        },
      },


    }
  },
  methods: {
    onETicket (id, row) {
      if (this.loading) return
      this.electronicTicket.id = id
      this.electronicTicket.obj = row
      this.electronicTicket.visible = true
    },
    onMealTicket (id, i, item) {
      if (this.loading) return
      this.mealTicket.id = id
      this.mealTicket.meetId = item.meet.id
      this.getMealTicketList()
    },
    async onPay (id, row) {
      if (this.tabActive == 0 && Number(row.amount) <= 0) {
        let params = {
          join_meet_id: id,
          amount: row.amount,
          pay_type: 5,
          duan_type: 2,
          ticket_id: row.meet.id,
        }
        let res = await set_ticketPay(params)
        if (res) {
          this.AllList[0].data.find(v => v.id == id).pay_status = 1
          this.$message.success('免单成功')
        }
      } else {
        this.$router.push({ path: '/onlinePayment', query: { type: this.tabActive + 1, orderId: id } })
      }
    },
    onDetail (id) {
      this.$router.push({ path: this.tabActive == 0 ? '/personal/meetingOrderDetail' : '/personal/hotelDetails', query: { orderId: id } })
    },
    changeMealTicket (ix) {
      this.mealTicket.tabActive = ix
      if (this.mealTicket.AllList[ix].first) return
      this.getMealTicketList()

    },
    async getMealTicketList () {
      this.loading = true
      var res = await get_mealTicket({ limit: 1000, page: 1, status: this.mealTicket.tabActive + 1, meet_id: this.mealTicket.meetId })
      this.loading = false
      if (res) {
        this.mealTicket.AllList[this.mealTicket.tabActive].first = true
        this.mealTicket.AllList[this.mealTicket.tabActive].total = res.total
        this.mealTicket.AllList[this.mealTicket.tabActive].data = res.data
        this.mealTicket.visible = true
      }
    },

    onMerchant (x) {
      if (!x) return this.$message.success('当前酒店已关闭线上预定')
      var input = document.getElementById('input')
      input.value = x
      input.select()
      document.execCommand('copy')
      this.$message.success('商家联系方式已复制！')
    },
    onCancel (id, i) {
      if (this.loading) return
      this.$confirm('您确认取消订单吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        var res = this.tabActive == 0 ? await set_cancelJoin({ order_id: id }) : await set_cancelRoom({ order_id: id })
        if (res) {
          this.loading = false
          this.tabActive == 0 ? this.AllList[this.tabActive].data.find(v => v.id == id).pay_status = 3 : this.AllList[this.tabActive].data.find(v => v.id == id).status = 4
          this.$message.success('订单已取消')
        } else {
          this.loading = false
          this.$message.warning('操作失败')
        }
      }).catch(() => { })
    },
    onRefund (id, i) {
      this.$confirm('您确认申请退款吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        var res = await set_refund({ id: id })
        if (res) {
          this.AllList[this.tabActive].data.find(v => v.id == id).pay_status = 4
          this.$message.success('退款申请已提交')
        } else {
          this.$message.warning('操作失败')
        }
      }).catch(() => { })
    },
    changeTab (ix) {
      this.tabActive = ix
      if (this.AllList[this.tabActive].first) return
      this.getList()
    },
    pageChange (c) {
      this.AllList[this.tabActive].page = c
      this.getList()
    },
    sizeChange (e) {
      this.AllList[this.tabActive].page = 1
      this.AllList[this.tabActive].size = e
      this.getList()
    },
    async getList () {
      if (this.tabActive == 0) {
        let res = await get_schedule_list({
          type: 2,
          limit: this.AllList[0].size,
          page: this.AllList[0].page,
        })
        if (res) {
          this.AllList[0].data = res.data
          if (!this.AllList[0].total) this.AllList[0].total = res.total
          if (!this.AllList[0].first) this.AllList[0].first = true
        }
      } else {
        let res = await get_hotelOrder({
          page: this.AllList[1].page,
          limit: this.AllList[1].size
        })
        if (res) {
          this.$set(this.AllList[this.tabActive], 'data', res.data)
          this.$set(this.AllList[this.tabActive], 'total', res.total)
          this.$set(this.AllList[this.tabActive], 'first', true)
        }
      }
    }
  },
  created () {
    this.tabActive = this.$route.query.tabActive ? this.$route.query.tabActive : 0
    this.getList()
  },
  mounted () { },
  destroyed () { },
  computed: {
    userInfo () {
      return this.$store.getters.personal
    },
    changePage () {
      return this.$store.getters.uploadBack
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.orderTypeList {
  width: calc(100% - 30px);
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  margin-left: 30px;
  .typeCurr {
    color: #ff0036;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #ff0036;
      bottom: -16px;
      left: 0;
    }
  }
  span {
    font-size: 16px;
    color: #333333;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    float: left;
    cursor: pointer;
    border-right: 1px solid #dfe4e8;
  }
}
.pagination {
  margin: 60px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #47d7e3;
  color: #fff;
}
.row_box {
  cursor: pointer;
  margin: 30px;
  border: 1px solid #caebfc;
  margin-bottom: 13px;
  font-size: 13px;
  color: #333;
  background-color: #fff;
  .top {
    padding: 0 30px 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #f7fbff;
    font-size: 13px;
    color: #333;
  }
  .center {
    height: 115px;
    width: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    > div:nth-child(1) {
      width: 370px;
      padding-right: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        max-width: 145px;
        max-height: 86px;
      }
      p {
        text-align: left;
        width: calc(100% - 160px);
        display: block;
        overflow: hidden;
        text-overflow: ellispsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 194px;
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      padding: 0 8px;
      width: calc(1005 - 160px);
      overflow: hidden;
      text-overflow: ellispsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > div:nth-child(3) {
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 82px;
      span:nth-child(1) {
        margin-bottom: 10px;
      }
    }
    > div:nth-child(4) {
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      font-weight: 700;
      font-size: 16px;
    }
    .button_box {
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      width: 180px;
      text-align: center;
      display: flex;
      flex-direction: column;
      // align-content: center;
      justify-content: center;
      .el-button {
        align-self: center;
        width: 73px;
        height: 28px;
        font-size: 13px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 5px;
        border: 0;
        padding: 0;
      }
      > p {
        display: flex;
        justify-content: center;
        .el-button:last-child {
          margin-left: 8px;
        }
      }
      .btn1 {
        background: #47d7e3;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}
.row_box2 {
  cursor: pointer;
  margin: 30px;
  border: 1px solid #caebfc;
  margin-bottom: 13px;
  font-size: 13px;
  color: #333;
  background-color: #fff;
  .top {
    padding: 0 30px 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #f7fbff;
    font-size: 13px;
    color: #333;
    .active {
      color: #fd2250;
    }
  }
  .center {
    height: 115px;
    width: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    > div:nth-child(1) {
      width: 570px;
      padding-right: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        max-width: 94px;
        max-height: 63px;
      }
      .img1 {
        width: 94px;
        height: 63px;
        margin-right: 20px;
      }
      .img {
        width: 94px;
        height: 63px;
        background-color: rgba(0, 0, 0, 0.05);
      }
      .p1 {
        width: 100px;
        text-align: left;
        display: block;
        overflow: hidden;
        text-overflow: ellispsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-right: 20px;
      }
      .p12 {
        width: 100px;
        height: 16px;
        margin-right: 20px;
        background-color: rgba(0, 0, 0, 0.05);
      }
      .p2 {
        width: 336px;
        text-align: left;
        display: block;
        overflow: hidden;
        text-overflow: ellispsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > div:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      width: 135px;
      line-height: 115px;
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      padding: 0 8px;
      overflow: hidden;
      text-overflow: ellispsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .button_box {
      border-left: 1px solid #caebfc;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      flex-direction: column;
      // align-content: center;
      justify-content: center;
      .el-button {
        align-self: center;
        width: 73px;
        height: 28px;
        font-size: 13px;
        line-height: 28px;
        margin-bottom: 5px;
        border: 0;
        padding: 0;
      }
      p {
        display: flex;
        justify-content: center;
        .el-button:last-child {
          margin-left: 8px;
        }
      }
      .btn1 {
        background: #47d7e3;
        border-radius: 2px;
        color: #fff;
      }
      width: 180px;
    }
  }
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog {
  background-color: transparent;
}
.pop_close {
  position: absolute;
  bottom: -110px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  i {
    display: inline-block;
    background-color: #fff;
    height: 60px;
    width: 6px;
  }
  img {
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
}
.electronicTicket_box {
  position: relative;
  box-sizing: border-box;
  .content_box {
    border-radius: 20px;
    overflow: hidden;
    // height: 500px;
    padding: 60px 30px 110px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 250px;
      height: 250px;
      margin-bottom: 30px;
    }
    .p1 {
      text-align: center;
      font-size: 25px;
      color: #333;
      font-weight: 700;
    }
    .p2 {
      margin-top: 20px;
      font-size: 18px;
      color: #333;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.mealTicket_box {
  background-color: #fff;
  height: 570px;
  border-radius: 20px;
  overflow: hidden;
  .tab_box {
    height: 78px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid #ebebeb;
    cursor: pointer;
    > span {
      box-sizing: border-box;
      text-align: center;
      position: relative;
      flex: 1;
      height: 100%;
      line-height: 77px;
      font-size: 16px;
      color: #333;
      font-weight: 700;
      &:last-child {
        border-left: 1px solid #ebebeb;
      }
    }
    .active {
      color: #47d7e3;
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        z-index: 4;
        left: 50%;
        transform: translateX(-50%);
        width: 47px;
        height: 4px;
        background: #47d7e3;
      }
    }
  }
  .content {
    height: 460px;
    overflow-y: auto;
    .tab2 .left > p {
      color: #b7b7b7 !important;
    }
    .row {
      position: relative;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      .left {
        width: calc(100% - 120px);
        > p:nth-child(1) {
          color: #777777;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 6px;
        }
        > p:nth-child(2) {
          color: #333333;
          font-size: 26px;
          line-height: 37px;
          font-weight: 700;
          margin-bottom: 12px;
          span:last-child {
            margin-left: 20px;
            color: #333333;
            font-size: 12px;
            font-weight: normal;
          }
        }
        > p:nth-child(3) {
          color: #333333;
          font-size: 14px;
          line-height: 22px;
          span {
            display: inline-block;
            margin-right: 14px;
            margin-bottom: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        > p:nth-child(4) {
          font-weight: 700;
          color: #343434;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 4px;
        }
        > p:nth-child(5) {
          font-weight: 700;
          color: #343434;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .right {
        text-align: right;
        padding-right: 15px;
        img {
          margin-top: 10px;
          width: 113px;
          height: 85px;
        }
        .code {
          width: 69px;
          height: 69px;
        }
      }
    }
  }
}
</style>
